import { render, staticRenderFns } from "./ButtonsMap.vue?vue&type=template&id=0d5bec6a&scoped=true&"
import script from "./ButtonsMap.vue?vue&type=script&lang=js&"
export * from "./ButtonsMap.vue?vue&type=script&lang=js&"
import style0 from "./ButtonsMap.vue?vue&type=style&index=0&id=0d5bec6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5bec6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBtn,VIcon,VSpeedDial,VTooltip})
